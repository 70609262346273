<template>
  <div class="container">
    <vue-element-loading :active="running" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#36F"/>
    <div>
      <form>
        <b-form-group>
          <p>&nbsp;</p>
          <b-form-input size="lg" type="text" v-model="uid" :placeholder="$t('message.login_uid2')" required maxlength="32"></b-form-input>
        </b-form-group>
        <b-form-group>
          <p>&nbsp;</p>
          <b-form-input size="lg" type="password" autocomplete="false" v-model="pas" :placeholder="$t('message.login_passwd')" required maxlength="16"></b-form-input>
        </b-form-group>
        <b-form-group>
          <p>&nbsp;</p>
          <b-form-input size="lg" type="password" autocomplete="false" v-model="repas" :placeholder="$t('message.login_repasswd')" required maxlength="16"></b-form-input>
        </b-form-group>
        <b-form-group>
          <p>&nbsp;</p>
          <b-form-input size="lg" type="text" v-model="nickname" :placeholder="$t('message.login_nickname')" required maxlength="16"></b-form-input>
        </b-form-group>
        <b-form-group>
          <p>&nbsp;</p>
          <b-button block class="mainbtn" variant="primary" @click="registernow" :disabled="running">{{$t('message.login_register_now')}}
          </b-button>
        </b-form-group>
        <b-form-group>
          <div class="loginmsg">{{errormsg}}&nbsp;</div>
          <div class="text-right mt-2">
            <b-button :to="{path:'/login',query:{backid:$route.query.backid}}" variant="primary">{{$t('message.login_btn_login')}}</b-button>
          </div>
        </b-form-group>
      </form>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { APPVERSION } from '@/config'
  import { recaptchasitekey } from '@/config'
  import { VueReCaptcha } from 'vue-recaptcha-v3'
  Vue.use(VueReCaptcha, { siteKey: recaptchasitekey, loaderOptions: {useRecaptchaNet: true} });
  export default {
    name: 'register',
    mounted() {
    },
    data() {
      return {
        running: false,
        uid: '',
        pas: '',
        repas: '',
        nickname: '',
        errormsg: APPVERSION
      };
    },
    methods: {
      async registernow() {
        if (!this.uid || this.uid.length<3) {
          this.errormsg = this.$t('message.login_uid_too_short');
        } else if (!this.pas || this.pas.length<5) {
          this.errormsg = this.$t('message.login_pas_too_short');
        } else if (!this.nickname || this.nickname.length<2) {
          this.errormsg = this.$t('message.register_nickname_too_short');
        } else if (this.pas != this.repas) {
          this.errormsg = this.$t('message.registerpass_diff');
        } else if (this.uid.indexOf('@')<2 || this.uid.indexOf('.')<5) {
          this.errormsg = this.$t('message.uid_invalidemail');
        } else {
          this.errormsg = '';
          this.running = true;
          await this.$recaptchaLoaded();
          let recaptoken = await this.$recaptcha('login');
          let params = 'uid='+this.uid+'&pas='+this.$md5(this.pas)+'&repas='+this.$md5(this.repas)+'&nick='+this.nickname+'&recapt='+recaptoken;
          let axresp = await this.axios.post('/register?tm='+new Date().getTime(), params);
          this.running = false;
          if (axresp.data.rc==-1) {
            this.errormsg = this.$t('message.register_exists');
          } else if (axresp.data.rc==1) {
            this.errormsg = this.$t('message.register_OK');
            localStorage.setItem('token', axresp.data.token);
            localStorage.setItem('nickname',axresp.data.nickname);
            if(this.$route.query.backid){
              if (Number(this.$route.query.backid)<50000) {
                this.$router.push('chargerbk/'+this.$route.query.backid);
              } else {
                this.$router.push('chargerev/'+this.$route.query.backid);
              }
            }
          } else {
            this.errormsg = axresp.data.rm;
          }
        }
      },
    },
  }
</script>
